.article {
  padding-bottom: $base * 5;
  min-height: 90vh;
  @include respond-to("medium-down") {
    padding-bottom: $base * 8;
  }
  &__wrap {
    @include respond-to("medium-down") {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-gap: $base;
    }
  }
  h3 {
    margin-bottom: $base * 3;
  }
  a {
    text-decoration: underline;
  }
  img {
    margin-top: $base * 2;
    margin-bottom: $base * 4;
  }
  &__content {
    @include respond-to("medium-down") {
      border-right: 1px solid $orange4;
      padding-right: $base * 8;
    }
  }
  &__sidebar {
    padding: $base * 3 $base * 3 0 $base * 3;
    margin-top: $base * 3;
    border-top: 1px solid $orange4;
    @include respond-to("medium-down") {
      border-top: 0;
      padding: 0 $base * 3;
      margin-top: 0;
    }
  }
  &__sidebar-header {
    text-align: center;
    font-size: 22px;
    font-weight: 800;
  }
  .button-group {
    margin-top: $base * 3;
    margin-left: 0;
    justify-content: center;
    .circular-button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
