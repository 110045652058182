.footer {
  background: $pt-intent-primary;
  color: #fff;
  font-family: "Nunito", sans-serif;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: 0.2s ease all;
    &:hover {
      transition: 0.2s ease all;
      text-decoration: underline;
    }
  }
  @include respond-to("medium-down") {
    height: 262px;
  }
  &__wrap {
    @include respond-to("medium-down") {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 3fr;
    }
  }
  &__logo {
    width: 130px;
    margin-bottom: $base * 4;
    @include respond-to("medium-down") {
      margin-bottom: 0;
    }
    a {
      color: #fff;
    }
    img {
      margin-bottom: $base * 2;
    }
  }
  &__icon-row {
    display: flex;
    span {
      display: inline-block;
      margin-right: $base;
    }
  }
  &__header {
    margin-bottom: $base * 2;
  }
  &__block {
    justify-self: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    opacity: 0.9;
    margin-bottom: $base * 4;
    @include respond-to("medium-down") {
      margin-bottom: 0;
    }
    ul {
      text-transform: initial;
      font-weight: 600;
    }
    li {
      margin-bottom: $base * 1.5;
      line-height: 20px;
    }
  }
  &__social {
    padding-bottom: $base * 4;
    @include respond-to("medium-down") {
      padding-bottom: 0;
    }
    a {
      display: flex;
      font-weight: 800 !important;
      margin-bottom: 0;
    }
    span {
      margin-left: $base * 2;
      display: inline-block;
      margin-top: -10px;
    }
  }
  &__right {
    justify-self: end;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    opacity: 0.9;
  }
}
