.tag {
  background: $pt-intent-primary-light;
  display: inline-block;
  border-radius: 50px;
  padding: $base $base*2;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: $base*3;
  @include respond-to("medium-down") {
    font-size: 14px;
    padding: $base*1.5 $base*3;
    margin-bottom: $base*4;
  }
}