.tracker {
  width: 100%;
  background-color: $pt-intent-secondary-light;
  padding-top: $base * 3;
  padding-bottom: $base * 2;
  @include respond-to("medium-down") {
    height: 200px;
    display: flex;
    justify-content: center;
    padding-top: $base * 2;
  }
  .button-group {
    // display: none;
    margin: $base * 2 0;
    @include respond-to("medium-down") {
      display: flex;
      grid-gap: 0;
    }
  }
  h4 {
    margin-bottom: 0;
    @include respond-to("medium-down") {
      margin-bottom: $gutter;
    }
    span {
      font-size: 16px;
      font-weight: 600;
      @include respond-to("medium-down") {
        font-size: 22px;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  &__block {
    padding-bottom: $base * 4;
    margin-bottom: $base * 3;
    border-bottom: 1px solid rgba(194, 86, 39, 0.25);
    @include respond-to("medium-down") {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &__wrap {
    @include respond-to("medium-down") {
      display: grid;
      grid-template-columns: 4fr 1fr;
      grid-gap: $base * 4;
    }
  }
  &__bar {
    width: 100%;
    height: 15px;
    background: #fff;
    border-radius: 100px;
    margin-top: $base * 1.5;
    margin-bottom: $base * 1.5;
    @include respond-to("medium-down") {
      margin-top: $base * 2.5;
      margin-bottom: $base * 2.5;
    }
    &--inner {
      height: 15px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background: $pt-intent-secondary;
    }
  }
}
