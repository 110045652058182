.hero {
  height: 400px;
  background-color: $pt-app-background-color;
  background-image: url('../../assets/images/hero-image-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @include respond-to("small-down") {
    height: 500px;
  }
  @include respond-to("medium-down") {
    height: 700px;
    max-height: 700px;
  }
  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.75);
    z-index: 0;
    height: 400px;
    @include respond-to("small-down") {
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 60%);
    }
    @include respond-to("small-down") {
      height: 500px;
    }
    @include respond-to("medium-down") {
      height: 700px;
      max-height: 700px;
    }
  }
  &__wrap {
    height: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
    @include respond-to("medium-down") {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-content: center;
    }
  }
  &__content {
    width: 100%;
    @include respond-to("medium-down") {
      max-width: 500px;
    }
    z-index: 100;
    h5 {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h4 {
    @include respond-to("medium-down") {
      width: 400px;
    }
  }
  &__video {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  &__play {
    width: 110px;
    height: 110px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $pt-transition-ease;
    &:hover {
      transition: $pt-transition-ease;
      -webkit-box-shadow: 10px 10px 13px -6px rgba(0,0,0,0.39);
      -moz-box-shadow: 10px 10px 13px -6px rgba(0,0,0,0.39);
      box-shadow: 10px 10px 13px -6px rgba(0,0,0,0.39);
    }
  }
}