.button {
  &.bp3-button {
    font-family: "Nunito", sans-serif;
    background-color: $pt-app-background-color;
    background-image: none;
    box-shadow: none;
    height: 45px;
    border-radius: 100px;
    min-width: 130px;
    font-weight: 800;
    font-size: 16px;
    color: $pt-intent-primary;
    transition: $pt-transition-ease;
    &:hover {
      background-color: $pt-intent-primary-light;
      transition: $pt-transition-ease;
      box-shadow: none;
    }
    &.bp3-intent-primary {
      background-color: $pt-intent-secondary;
      color: #fff;
      text-transform: uppercase;
      padding-left: $base * 4;
      padding-right: $base * 4;
    }
  }
}

.close-widget {
  position: absolute;
}

.round-button {
  background: $orange3;
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
  margin: auto;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: $base * 3;
  min-height: 50px;
  cursor: pointer;
  transition: $pt-transition-ease;
  width: 100%;
  text-align: center;
  &:hover {
    background: lighten($orange3, 3%);
    transition: $pt-transition-ease;
  }
  @include respond-to("medium-down") {
    padding: $base * 3 $base * 4;
  }
}

.button-center {
  margin: auto;
  a {
    margin: auto;
  }
}

.button-wrap {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: auto;
}

.button-group {
  display: grid;
  align-items: center;
  margin-left: $base * 3;
  grid-template-columns: 1fr 1fr;
  grid-gap: $base * 2;
  a {
    margin-bottom: 0;
    text-decoration: none;
  }
}
.button-mobile {
  width: 100%;
}

.circular-button {
  height: 50px;
  padding: 0 $base * 4;
  background: $orange3;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $pt-intent-primary;
  transition: $pt-transition-ease;
  cursor: pointer;
  @include respond-to("medium-down") {
    height: 62px;
    margin-left: $base/2;
    margin-right: $base/2;
  }
  span {
    margin-left: $gutter;
    color: #9a5430;
    font-size: 18px;
    font-weight: 800;
    display: block;
  }
  &:hover {
    background: lighten($orange3, 3%);
    transition: $pt-transition-ease;
  }
  &--ghost {
    background: transparent;
    border: solid 2px $orange3;
  }
}

$buttons-size: 40px;
$buttons-expand-width: 90px;
$buttons-color: #b5bcc2;
$buttons-color-hover: #8196ab;

/* Navbar Elements*/
.nav li {
  height: $buttons-size;
  width: $buttons-size;
  background: $buttons-color;
  margin-right: 15px;
  // float: left;
  -webkit-border-radius: $buttons-size;
  -moz-border-radius: $buttons-size;
  border-radius: $buttons-size;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  transition: width 0.3s;
  overflow: hidden;
  display: flex;

  a {
    color: rgba(0, 0, 0, 0.3);
    font-size: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    display: none;

    span {
      display: inline-block;
      transition: opacity 0.3s ease 0s, transform 0.5s ease 0s;
      -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
      -moz-transition: opacity 0.3s ease 0s, -moz-transform 0.5s ease 0s;
      line-height: $buttons-size;
      font-size: 14px;
      width: 100%;
      &:after {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0;
        opacity: 1;
        content: attr(data-hover);
      }
    }
  }
}

/* Hover State */
.nav li:hover {
  width: $buttons-expand-width;
  background: $buttons-color-hover;
  a {
    color: #f0f0f0;
    display: block;
    span {
      &:before {
        opacity: 1;
      }
    }
  }
}
