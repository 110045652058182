/**
 * CUSTOM VARIABLES
 * ~@blueprintjs/core/src/common/colors
 */

// Colours
$border-inactive: #e5e5e5;
$border-focus: #000;
$input-border: #c9c9c9;

//Spacing
$base: 0.5rem;
$gutter: 0.75rem;

//Transitions
$default-transition: 0.2s all ease;

// Form Fields
$input-radius: 3px;
$input-height: 40px;

@import "blueprint-colors";
@import "blueprint-color-aliases";
@import "blueprint-variables";
