.sponsors {
  width: 100%;
  margin-top: $base * 4;
  padding-bottom: $base * 10;
  .section__header:after {
    width: 80%;
  }
  &.section {
    padding-bottom: $base * 6;
    @include respond-to("medium-down") {
      padding-bottom: $base * 10;
    }
  }
  @include respond-to("medium-down") {
    margin-top: $base * 10;
  }
  ul {
    margin-top: $base * 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $base;
    @include respond-to("small-down") {
      margin-top: $base * 4;
      grid-gap: $base * 1.5;
      grid-template-columns: 1fr 1fr;
    }
    @include respond-to("medium-down") {
      margin-top: $base * 4;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &__logo {
    width: 110px;
    justify-self: center;
    @include respond-to("medium-down") {
      width: 130px;
    }
  }
}

.major-sponsors {
  &__list {
    @include respond-to("small-down") {
      display: flex;
      justify-content: space-around;
    }
  }
  &__logo {
    width: 80%;
    @include respond-to("small-down") {
      width: 40%;
    }
    @include respond-to("medium-down") {
      width: 32%;
    }
  }
}

.sponsors-home {
  margin-top: $base * 3;
}
