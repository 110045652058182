.our-supporters {
  margin-top: 75px;
  padding-top: 0;
  @include respond-to("medium-down") {
    margin-top: 90px;
  }
  &.section {
    padding-top: 0;
  }
  .sponsors {
    margin-top: 0;
    padding-top: $base * 6;
    @include respond-to("medium-down") {
      padding-top: $base * 10;
    }
  }
  &__header {
    margin-top: $base * 6;
    &:after {
      width: 75% !important;
    }
  }
  &__wrap {
    padding-top: $base * 2;
    margin-top: $base * 7;
    border-top: 1px solid rgba(194, 86, 39, 0.25);
  }
  ul {
    margin-top: $base * 4;
    display: grid;
    grid-gap: $base * 1.25;
    grid-template-columns: 1fr 1fr;
    @include respond-to("medium-down") {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  li {
    font-size: 16px;
    font-weight: 700;
    margin-top: $base * 1.2;
    margin-bottom: $base * 1.2;
  }
}
