.community {
  margin-top: $base * 4;
  h5 {
    margin-top: $base * 4;
  }
  &__wrap {
    padding: 0 $base * 2;
    position: relative;
    @include respond-to("medium-down") {
      border: 1px solid $orange4;
      padding: $base * 3 $base * 8;
    }
  }
  &__subhead {
    margin-top: 0;
    @include respond-to("medium-down") {
      margin-top: $base * 4;
    }
  }
  &__block {
    padding-top: $base * 2;
    padding-bottom: $base * 3;
    display: flex;
    flex-direction: column;
    @include respond-to("medium-down") {
      padding-top: $base * 7;
      padding-bottom: $base * 7;
      display: grid;
      grid-gap: $base * 5;
    }
    &--left {
      position: relative;
      @include respond-to("medium-down") {
        grid-template-columns: 3fr 3fr 1fr;
      }
      &.first {
        &:after {
          @include respond-to("medium-down") {
            content: "";
            position: absolute;
            width: 90%;
            height: 1px;
            bottom: 0;
            left: -64px;
            border-bottom: 1px solid $orange4;
          }
        }
      }
    }
    &--right {
      position: relative;
      &:after {
        @include respond-to("medium-down") {
          content: "";
          position: absolute;
          width: 90%;
          height: 1px;
          bottom: 0;
          right: -64px;
          border-bottom: 1px solid $orange4;
        }
      }
      @include respond-to("medium-down") {
        grid-template-columns: 1fr 3fr 3fr;
      }
    }
  }
  &__image {
    width: 100%;
    order: 1;
    @include respond-to("medium-down") {
      order: 0;
    }
  }
  &__header {
    @include respond-to("medium-down") {
      position: absolute;
      top: -20px;
      left: -2px;
      background: #fff;
      padding-bottom: $base * 3;
      padding-right: $base * 5;
    }
  }
  &__text {
    order: 2;
    margin-top: $base * 2;
    @include respond-to("medium-down") {
      order: 0;
      margin-top: 0;
    }
    a {
      font-size: 18px;
      font-weight: 800;
      color: $pt-intent-primary;
      margin-top: $base * 4;
      display: block;
    }
  }
  &__share {
    order: 3;
    @include respond-to("medium-down") {
      order: 0;
    }
  }
}

.video-placeholder {
  position: relative;
  cursor: pointer;
  transition: 0.2s ease all;
  &:hover {
    filter: brightness(1.2);
    transition: 0.2s ease all;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.7;
  }
}
