.section {
  padding-top: $gutter * 3;
  @include respond-to("medium-down") {
    padding-top: $gutter * 4;
  }
  &__header {
    position: relative;
    margin-bottom: $base * 2;
    span {
      background-color: #fff;
      margin-right: $base * 2;
      padding-right: $base * 4;
    }
    &:after {
      content: "";
      position: absolute;
      width: 90%;
      height: 1px;
      top: 50%;
      right: 0;
      border-bottom: 1px solid rgba(194, 86, 39, 0.25);
      z-index: -1;
    }
  }
}

.donate-home {
  .donation-slider {
    margin-top: $base * 4;
    @include respond-to("medium-down") {
      margin-top: $base * 7;
    }
  }
}

.fade {
  transform: none !important;
}

.top-section {
  margin-top: 75px;
  @include respond-to("medium-down") {
    margin-top: 90px;
  }
}

.container {
  max-width: 1160px;
  width: 100%;
  margin: auto;
  padding: 0 $base * 3;
  @include respond-to("small-down") {
    padding: 0 $base * 8;
  }
  @include respond-to("medium-down") {
    padding: 0 $base * 4;
  }
}

.container label {
  // display: none !important;
}

.payment {
  &__wrap {
    margin-top: $base * 2;
    @include respond-to("medium-down") {
      display: flex;
    }
  }
  .section__header {
    h3 {
      font-size: 26px;
    }
    margin-top: $base * 3;
  }
  .left-col {
    width: 100%;
    transition: ease 0.4s all;
    &.active {
      @include respond-to("medium-down") {
        width: 65%;
        transition: ease 0.4s all;
        padding-right: $base * 10;
      }
    }
    // border-right: 1px solid rgba(194, 86, 39, 0.25);
  }
  .close-widget {
    position: fixed;
    top: 18px;
    left: 12px;
    background-color: #fcd7ad;
    border-radius: 100%;
    padding: $base;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to("medium-down") {
      display: none;
    }
  }
  .right-col {
    transition: ease 0.4s all;
    visibility: hidden;
    position: absolute;
    top: 84px;
    left: 0;
    @include respond-to("medium-down") {
      position: unset;
      visibility: visible;
      width: 0;
      opacity: 0;
      transform: translateX(600px);
    }
    &.active {
      visibility: visible;
      width: 100%;
      z-index: 9999;
      @include respond-to("medium-down") {
        width: 35%;
        opacity: 1;
        transition: ease 0.4s all;
        transform: translateX(0);
        border-left: 1px solid rgba(194, 86, 39, 0.25);
        z-index: 1;
      }
    }
    // display: none;
  }
}

.disclaimer {
  max-width: 600px;
  line-height: 22px;
  margin: auto;
  text-align: left;
  font-size: 14px;
  margin-top: $base * 2;
  padding-top: $base * 4;
  color: #777;
  border-top: 1px solid #eee;
  @include respond-to("medium-down") {
    margin-top: $base * 8;
    text-align: center;
  }
}

.credit {
  opacity: 0.75;
  padding-bottom: $base * 2;
  a {
    margin-bottom: 0;
  }
  @include respond-to("medium-down") {
    margin-top: $base;
    text-align: right;
  }
}
