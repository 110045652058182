.input {
  input,
  select {
    // height: 50px;
    width: 100%;
    border: 1px solid $pt-intent-secondary;
    border-radius: 50px;
    padding-left: $base * 3;
    padding-right: $base * 3;
    color: $pt-intent-primary;
    font-weight: 400;
    font-size: 16px;
  }
  select {
    min-width: 150px;
  }
  label {
    display: block;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: $gutter;
  }
}

.form {
  &__row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $base * 3;
    margin-bottom: $base * 4;
    input,
    select {
      width: 100%;
    }
    &--2-col {
      grid-template-columns: 1fr 1fr;
    }
    &--3-col {
      grid-template-columns: 1fr 2fr 2fr;
    }
  }
}

.payment-details {
  max-width: 700px;
  margin: auto;
}

.payment-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 700px;
  margin: auto;
  align-items: center;
  justify-items: end;
  &__wrap {
    order: 1;
  }
  .rccs {
    order: 2;
    margin: 0;
  }
}
