.preview {
  h4 {
    margin-top: $base * 4;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    margin-bottom: 0;
  }
  img {
    margin-top: $base * 4;
    margin-bottom: $base * 3;
    @include respond-to("medium-down") {
      margin-top: 0;
      margin-bottom: $base * 4;
    }
  }
  &__block {
    // margin-bottom: $base*3;
    &--left {
      @include respond-to("medium-down") {
        display: grid;
        grid-template-columns: 4fr 6fr;
        grid-gap: $base * 4;
      }
    }
  }
  &__text {
    width: 100%;
    @include respond-to("medium-down") {
      width: 85%;
    }
  }
}
