.bold {
  font-weight: 900;
}

.italicise {
  font-style: italic;
}

.bp3-overlay.bp3-overlay-scroll-container {
  z-index: 9999;
}
