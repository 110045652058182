/**
 * BLUEPRINT VARIABLES
 * "~@blueprintjs/core/src/common/variables";
 */

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

// // Namespace appended to the beginning of each CSS class: `.#{$ns}-button`.
// // Do not quote this value, for Less consumers.
// $ns: bp3;

// // easily the most important variable, so it comes up top
// // (so other variables can use it to define themselves)
// $pt-grid-size: 10px;

// // see https://bitsofco.de/the-new-system-font-stack/
$pt-font-family: 'Nunito', sans-serif;

// $pt-font-family-monospace: monospace;

// $pt-font-size: $pt-grid-size * 1.4;
// $pt-font-size-large: $pt-grid-size * 1.6;
// $pt-font-size-small: $pt-grid-size * 1.2;

// // a little bit extra to ensure the height comes out to just over 18px (and browser rounds to 18px)
// $pt-line-height: ($pt-grid-size * 1.8) / $pt-font-size + 0.0001;

// // Icon variables

// $icons16-family: "Icons16";
// $icons20-family: "Icons20";

// $pt-icon-size-standard: 16px;
// $pt-icon-size-large: 20px;

// // Grids & dimensions

// $pt-border-radius: floor($pt-grid-size / 3);

// // Buttons
// $pt-button-height: $pt-grid-size * 3;
// $pt-button-height-small: $pt-grid-size * 2.4;
// $pt-button-height-smaller: $pt-grid-size * 2;
// $pt-button-height-large: $pt-grid-size * 4;

// // Inputs
// $pt-input-height: $pt-grid-size * 3;
// $pt-input-height-large: $pt-grid-size * 4;
// $pt-input-height-small: $pt-grid-size * 2.4;

// // Others
// $pt-navbar-height: $pt-grid-size * 5;

// // Z-indices
// $pt-z-index-base: 0;
// $pt-z-index-content: $pt-z-index-base + 10;
// $pt-z-index-overlay: $pt-z-index-content + 10;

// // Shadow opacities
// $pt-border-shadow-opacity: 0.1;
// $pt-drop-shadow-opacity: 0.2;
// $pt-dark-border-shadow-opacity: $pt-border-shadow-opacity * 2;
// $pt-dark-drop-shadow-opacity: $pt-drop-shadow-opacity * 2;

// // Elevations
// // all shadow lists must be the same length to avoid flicker in transitions.
// $pt-elevation-shadow-0: 0 0 0 1px $pt-divider-black,
//                         0 0 0 rgba($black, 0),
//                         0 0 0 rgba($black, 0);
// $pt-elevation-shadow-1: border-shadow($pt-border-shadow-opacity),
//                         0 0 0 rgba($black, 0),
//                         0 1px 1px rgba($black, $pt-drop-shadow-opacity);
// $pt-elevation-shadow-2: border-shadow($pt-border-shadow-opacity),
//                         0 1px 1px rgba($black, $pt-drop-shadow-opacity),
//                         0 2px 6px rgba($black, $pt-drop-shadow-opacity);
// $pt-elevation-shadow-3: border-shadow($pt-border-shadow-opacity),
//                         0 2px 4px rgba($black, $pt-drop-shadow-opacity),
//                         0 8px 24px rgba($black, $pt-drop-shadow-opacity);
// $pt-elevation-shadow-4: border-shadow($pt-border-shadow-opacity),
//                         0 4px 8px rgba($black, $pt-drop-shadow-opacity),
//                         0 18px 46px 6px rgba($black, $pt-drop-shadow-opacity);

// $pt-dark-elevation-shadow-0: 0 0 0 1px $pt-dark-divider-black,
//                              0 0 0 rgba($black, 0),
//                              0 0 0 rgba($black, 0);
// $pt-dark-elevation-shadow-1: border-shadow($pt-dark-border-shadow-opacity),
//                              0 0 0 rgba($black, 0),
//                              0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity);
// $pt-dark-elevation-shadow-2: border-shadow($pt-dark-border-shadow-opacity),
//                              0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity),
//                              0 2px 6px rgba($black, $pt-dark-drop-shadow-opacity);
// $pt-dark-elevation-shadow-3: border-shadow($pt-dark-border-shadow-opacity),
//                              0 2px 4px rgba($black, $pt-dark-drop-shadow-opacity),
//                              0 8px 24px rgba($black, $pt-dark-drop-shadow-opacity);
// $pt-dark-elevation-shadow-4: border-shadow($pt-dark-border-shadow-opacity),
//                              0 4px 8px rgba($black, $pt-dark-drop-shadow-opacity),
//                              0 18px 46px 6px rgba($black, $pt-dark-drop-shadow-opacity);

// // Transitions
$pt-transition-ease: ease 0.2s all;
// $pt-transition-ease-bounce: cubic-bezier(0.54, 1.12, 0.38, 1.11);
// $pt-transition-duration: 100ms;

// // Light theme styles

// $pt-input-box-shadow: inset border-shadow(0.15),
//                       inset 0 1px 1px rgba($black, $pt-drop-shadow-opacity);

// $pt-dialog-box-shadow: $pt-elevation-shadow-4;
// $pt-popover-box-shadow: $pt-elevation-shadow-3;
// $pt-tooltip-box-shadow: $pt-popover-box-shadow;

// // Dark theme styles

// $pt-dark-input-box-shadow: inset border-shadow(0.3),
//                            inset 0 1px 1px rgba($black, $pt-dark-drop-shadow-opacity);

// $pt-dark-dialog-box-shadow: $pt-dark-elevation-shadow-4;
// $pt-dark-popover-box-shadow: $pt-dark-elevation-shadow-3;
// $pt-dark-tooltip-box-shadow: $pt-dark-popover-box-shadow;
