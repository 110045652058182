.progress {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 800px;
  margin: $base*14 auto $base*8 auto;
  position: relative;
  &:after {
    content:"";
    position: absolute;
    border-bottom: 1px solid $orange4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
  }
  &__step {
    justify-self: center;
    font-size: 18px;
    font-weight: 800;
    background: #fff;
    z-index: 100;
    padding: 0 $base*2;
    span {
      opacity: 0.4;
      &.active {
        opacity: 1;
      }
    } 
  }
}