.donation-slider {
  margin-top: $base * 3;
  margin-bottom: $base * 4;
  .large {
    font-size: 30px;
    font-weight: 900;
    margin-left: $gutter;
    margin-right: $gutter;
    display: inline-block;
    @include respond-to("small-down") {
      font-size: 38px;
    }
  }
  h3 {
    text-align: left;
    max-width: 650px;
    margin: auto;
    min-height: 130px;
    font-size: 22px;
    line-height: 32px;
    @include respond-to("small-down") {
      text-align: center;
      font-size: 28px;
      line-height: 40px;
    }
  }
  a {
    text-decoration: none;
  }
  &__image {
    width: 100%;
    margin: 0 auto;
    min-height: 265px;
    @include respond-to("small-down") {
      width: 600px;
      height: 460px;
      margin-bottom: $base * 8;
    }
    img {
      margin: 0;
    }
  }
  &__block {
    @include respond-to("small-down") {
      position: absolute;
      top: -50px;
      right: -270px;
      &.active {
        right: -230px;
      }
    }
  }
  &__amount {
    width: 160px;
    margin: auto;
    text-align: center;
    @include respond-to("small-down") {
      width: 220px;
    }
    input {
      font-size: 18px;
      font-weight: 700;
      color: $pt-intent-secondary;
      min-width: 0;
    }
  }
  &__block {
    display: flex;
    justify-content: center;
    @include respond-to("small-down") {
    }
  }
  &__input {
    position: relative;
    height: 46px;
    @include respond-to("small-down") {
      height: 58px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      background: red;
    }
  }
  label {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: $gutter;
  }
}

.active {
  .range-slider .StepRangeSlider {
    transition: ease 0.4s all;
    @include respond-to("small-down") {
      margin-left: -210px;
    }
  }
  .donation-slider__block {
    transition: ease 0.4s all;
    @include respond-to("small-down") {
      right: -220px;
    }
  }
  iframe {
    height: 1050px !important;
  }
}

.input-group {
  border: 1px solid rgba(194, 86, 39, 0.25);
  border-radius: 50px;
  display: flex;
  input {
    border: none;
  }
  &__currency {
    background: $pt-intent-primary-light;
    width: 150px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    border-right: 1px solid rgba(194, 86, 39, 0.25);
  }
}

.range-slider {
  max-width: 400px;
  margin: $base * 3 auto 0 auto;
  position: relative;
  transition: ease 0.4s all;
  &.active {
    transition: ease 0.4s all;
    @include respond-to("small-down") {
      width: 250px;
    }
  }
  .donation-slider__amount {
    margin: 0;
  }
  .button-mobile {
    max-width: 170px;
    @include respond-to("small-down") {
      max-width: 210px;
    }
  }
  .circular-button {
    justify-self: end;
    margin-top: 26px;
    border: 1px solid $pt-intent-secondary-light;
    margin-left: $base * 2;
  }
  .rangeslider {
    box-shadow: none;
    max-width: 350px;
  }
  .StepRangeSlider {
    width: 90%;
    margin: 0 auto $base * 5 auto;
    @include respond-to("small-down") {
      margin-left: -170px;
      width: 100%;
    }
  }
  .StepRangeSlider__track {
    box-shadow: none;
    position: absolute;
    border: 1px solid $pt-intent-primary;
    background: $pt-intent-primary;
  }
  .StepRangeSlider__tooltip {
    background: $pt-intent-primary-light;
    border-radius: 3px;
    color: #fff;
    bottom: 42px;
    padding: $base/2 $base;
    color: $pt-intent-primary;
    font-weight: 800;
    font-size: 16px;
    display: none;
  }
  .StepRangeSlider__thumb {
    background: $pt-intent-secondary;
    border: 0;
    outline: 0;
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    display: none;
  }
}
