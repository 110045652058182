h1,
h2,
h3,
h4,
h5,
h6,
p .h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
  font-family: 'Nunito', sans-serif;
  color: $pt-intent-secondary;
}

h1,
.h1 {
  font-size: 38px;
  font-weight: 900;
  margin-bottom: $base*2;
  line-height: 46px;
  @include respond-to("small-down") {
    margin-bottom: $base*4;
    font-size: 52px;
    line-height: 60px;
  }
  @include respond-to("medium-down") {
    margin-bottom: $base*4;
    font-size: 60px;
    line-height: 68px;
  }
}

h2,
.h2 {
	font-size: 36px;
}

h3,
.h3 {
	font-size: 24px;
  line-height: 34px;
  font-weight: 800;
  margin-bottom: $base*2;
  @include respond-to("medium-down") {
    font-size: 32px;
    line-height: 42px;
  }
}

h4,
.h4 {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: $gutter;
  line-height: 28px;
  @include respond-to("small-down") {
    font-size: 26px;
    line-height: 36px;
  }
}

h5,
.h5 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: $gutter;
  line-height: 30px;
}

h6,
.h6 {
	font-size: 16px;
}

p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: $base*2;
  font-weight: 600;
  color: #6d5b4b;
  @include respond-to("medium-down") {
    font-size: 18px;
  }
}

a {
  color: $pt-intent-primary;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: $base*2;
  display: block;
}