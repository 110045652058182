/**
 * VARIABLES
 */

//defaults...
@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/colors";

/**
 * BASE
 */

@import "~@blueprintjs/core/src/reset";
//@import "~@blueprintjs/core/src/typography";
@import "~@blueprintjs/core/src/accessibility/focus-states";

/**
 * USED COMPONENTS
 */

@import "~@blueprintjs/core/src/components/icon/icon";
@import "~@blueprintjs/core/src/components/callout/callout";
@import "~@blueprintjs/core/src/components/skeleton/skeleton";
@import "~@blueprintjs/core/src/components/button/button";
@import "~@blueprintjs/core/src/components/spinner/spinner";
@import "~@blueprintjs/core/src/components/dialog/dialog";
@import "~@blueprintjs/core/src/components/overlay/overlay";
@import "~@blueprintjs/core/src/components/portal/portal";
@import "~@blueprintjs/core/src/components/popover/popover";
@import "~@blueprintjs/core/src/components/collapse/collapse";

/**
 * AVALIABLE COMPONENTS

@import "~@blueprintjs/core/src/components/alert/alert";
@import "~@blueprintjs/core/src/components/breadcrumbs/breadcrumbs";
@import "~@blueprintjs/core/src/components/button/button-group";
@import "~@blueprintjs/core/src/components/card/card";
@import "~@blueprintjs/core/src/components/context-menu/context-menu";
@import "~@blueprintjs/core/src/components/divider/divider";
@import "~@blueprintjs/core/src/components/drawer/drawer";
@import "~@blueprintjs/core/src/components/editable-text/editable-text";
@import "~@blueprintjs/core/src/components/forms/index";
@import "~@blueprintjs/core/src/components/html-select/html-select";
@import "~@blueprintjs/core/src/components/html-table/html-table";
@import "~@blueprintjs/core/src/components/hotkeys/hotkeys";
@import "~@blueprintjs/core/src/components/menu/menu";
@import "~@blueprintjs/core/src/components/navbar/navbar";
@import "~@blueprintjs/core/src/components/non-ideal-state/non-ideal-state";
@import "~@blueprintjs/core/src/components/overflow-list/overflow-list";
@import "~@blueprintjs/core/src/components/panel-stack/panel-stack";
@import "~@blueprintjs/core/src/components/progress-bar/progress-bar";
@import "~@blueprintjs/core/src/components/slider/slider";
@import "~@blueprintjs/core/src/components/tabs/tabs";
@import "~@blueprintjs/core/src/components/tag/tag";
@import "~@blueprintjs/core/src/components/tag-input/tag-input";
@import "~@blueprintjs/core/src/components/toast/toast";
@import "~@blueprintjs/core/src/components/tooltip/tooltip";
@import "~@blueprintjs/core/src/components/tree/tree";

 */

.bp3-dialog,
button.bp3-button.bp3-intent-tertiary {
    background: #fff;
}
