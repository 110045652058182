.share-modal {
  &.bp3-dialog {
    width: 400px;
  }
  .bp3-dialog-header {
    padding-top: $base * 2;
    padding-bottom: $base * 2;
    text-align: center;
  }
  &__wrap {
    padding-top: $base * 2;
    .bp3-dialog-body {
      justify-content: center;
      display: flex;
    }
    button {
      display: inline-block;
      padding: 0 $gutter !important;
    }
  }
  .bp3-dialog-header .bp3-heading {
    white-space: normal;
    font-size: 22px;
    line-height: 30px;
  }
}
