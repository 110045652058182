.main-nav {
  background: #fff;
  width: 100%;
  height: 75px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  -webkit-box-shadow: 0px 11px 11px -9px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 11px 11px -9px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 11px 11px -9px rgba(0, 0, 0, 0.15);
  @include respond-to("medium-down") {
    height: 90px;
  }
  a {
    margin-bottom: 0;
    font-size: 16px;
  }
  &__wrap {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    @include respond-to("medium-down") {
      justify-content: space-between;
    }
  }
  &__menu {
    position: absolute;
    left: $base * 2;
    display: flex;
    align-items: center;
    height: 75px;
    @include respond-to("medium-down") {
      display: none;
    }
  }
  &__logo {
    width: 118px;
    display: flex;
    align-items: center;
    @include respond-to("medium-down") {
      width: 147px;
    }
    img {
      width: 100%;
    }
  }
  &__list {
    display: none;
    align-items: center;
    color: $pt-intent-primary;
    list-style-type: none;
    font-weight: 800;
    @include respond-to("medium-down") {
      display: flex;
    }
  }
  &__item {
    // margin-left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-to("medium-down") {
      margin-left: $base * 5;
    }
    a {
      height: 100%;
      display: flex;
      align-items: center;
    }
    &.active {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        border-bottom: 2px solid $pt-intent-primary;
      }
    }
  }
  .active-btn {
    button {
      background: $pt-intent-primary-light;
    }
  }
  .bp3-icon > svg:not([fill]) {
    fill: $pt-intent-primary;
  }
}

.mobile-main-nav {
  &__panel {
    width: 100%;
    height: 100vh;
    background: #fff;
  }
  &__list {
    padding-top: $base * 4;
  }
  &__item {
    padding: $base * 3 0;
    text-align: center;
    a {
      font-size: 22px;
    }
  }
  .hide {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    transition: 0.2s ease all;
  }
  .show {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
    transition: 0.2s ease all;
    position: fixed;
    top: 75px;
    left: 0;
  }
}
